import { thumbHashToDataURL } from "thumbhash";

const HASH_ATTRIBUTE_NAME = "data-thumbhash";
const BG_SIZE_ATTRIBUTE_NAME = "data-hash-background-size";
const BG_SIZE_DEFAULT = "cover"; // maintain aspect ratio

processNode(document.querySelector("body"));

const observer = new MutationObserver(onMutation);
observer.observe(document, {
  subtree: true,
  childList: true,
});

function onMutation(mutationList: MutationRecord[]) {
  mutationList.forEach((mutation) => mutation.addedNodes.forEach(processNode));
}

function processNode(node: Node | null) {
  if (node instanceof HTMLElement) {
    const element = node as HTMLElement;
    const elements = element.querySelectorAll(`[${HASH_ATTRIBUTE_NAME}]`);
    elements.forEach((el) => setPlaceholder(el as HTMLElement));
  }
}

function setPlaceholder(element: HTMLElement) {
  const thumbhash = element.getAttribute(HASH_ATTRIBUTE_NAME);
  if (thumbhash) {
    const dataBackgroundSize = element.getAttribute(BG_SIZE_ATTRIBUTE_NAME);
    const backgroundSize = dataBackgroundSize
      ? dataBackgroundSize
      : BG_SIZE_DEFAULT;
    const dataUrl = thumbHashToDataURL(decodeBase64(thumbhash));

    element.style.backgroundImage = `url(${dataUrl})`;
    element.style.backgroundSize = backgroundSize;
  }
}

function decodeBase64(base64: string): number[] {
  return atob(base64)
    .split("")
    .map((x) => x.charCodeAt(0));
}
